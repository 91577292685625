import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Landing from './Components/LandingPage';
import AsianCalc from './Components/AsianOptionPricingCalc';
import Layout from './Components/Layout';
import './index.css'
import LearnMore from './Components/LearnMore';
import VarianceReduction from './Components/VReduction';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path='/' Component={Landing} />
          <Route path="/asian-option-calculator" Component={AsianCalc} />
          <Route path="/learn-more" Component={LearnMore} />
          <Route path="/variance-reduction" Component={VarianceReduction} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
