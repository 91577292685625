import React from 'react';
import NavBar from './Nav';

const Layout = ({ children }) => (
  <div>
    <NavBar />
    {children}
  </div>
);

export default Layout;