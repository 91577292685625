import React, { useState } from 'react';
import Modal from 'react-modal'


function LearnMore(){
    const [showModal, setShowModal] = useState(true);

    const closeModal = () => {
        setShowModal(false);
    };

    return(
        <div>
            <Modal
                isOpen={showModal}
                onRequestClose={closeModal}
                contentLabel="Scroll for more information"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        zIndex: 1000,
                    },
                    content: {
                        width: '17%',
                        height: '17%',
                        margin: 'auto',
                        overflow: 'auto',
                        borderRadius: '8px',
                        padding: '20px',
                        top: '0%',
                        left: '75%',
                        bottom: '75%',
                        right: '0%',
                        border: 'none',
                        transition: 'opacity 0.5s ease-in-out',
                        opacity: showModal ? 1 : 0,
                    },
                }}
            >
                <div>
                    <p className='text-black text-sm font-bold mt-[10px] mb-8 text-center'>
                        Scroll for more information
                    </p>
                </div>
                    <button onClick={closeModal} style={{ display: 'block', margin: 'auto', border: '2px', borderStyle: 'solid', paddingLeft: '6px', paddingRight: '6px', backgroundColor:'rgb(211,211,211)'}}>
                        Close
                    </button>
            </Modal>
            <div className='max-w-[800ox] mt-[0px] w-full h-screen mx-auto text-center flex flex-col justify-center z-0 overflow-hidden'>
                <p className='text-white text-3xl font-bold mt-[10px] mb-10'>
                    What Are Options?
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base'>
                    Options are financial instruments that give the holder the right, but not the obligation, to buy or sell an underlying asset at a predetermined price within a specified time frame. These assets can include stocks, commodities, or even other financial instruments.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2'>
                    There are two main types of options: call options and put options. A call option gives the holder the right to buy the underlying asset at the predetermined price, known as the strike price. On the other hand, a put option gives the holder the right to sell the underlying asset at the strike price.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2'>
                Options provide investors with flexibility and opportunity in the financial markets. They can be used for various purposes, such as speculation, hedging, or generating income.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    Now, let's talk about Asian options. Asian options are a type of financial derivative contract that originated in Asian financial markets. They differ from traditional European or American options in how their payoff is calculated.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    Unlike European options, which are settled at a single point in time (usually at expiration), Asian options are settled based on the average price of the underlying asset over a predefined period, such as a week or a month. This averaging feature can help reduce the impact of short-term price fluctuations and provide a more stable outcome.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    Asian options are popular among investors and traders for their unique characteristics and ability to manage risk in volatile markets. They offer a different approach to trading options, providing opportunities for speculation and hedging based on the average price movement of the underlying asset over time.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    In summary, options are financial instruments that provide investors with the right to buy or sell an underlying asset at a predetermined price within a specified time frame. Asian options, specifically, are settled based on the average price of the underlying asset over a predefined period, offering a unique way to manage risk and speculate in the financial markets.
                </p>   
            </div>

            <div className='max-w-[800ox] mt-[0px] h-screen w-full mx-auto text-center flex flex-col justify-center z-0 overflow-hidden'>
                <p className='text-white text-3xl font-bold mt-[10px] mb-10'>
                    Types Of Asian Options
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base'>
                    Asian options, a class of financial derivatives originating in the dynamic Asian markets, offer investors distinctive avenues for managing risk and speculation. Set apart from traditional European or American options, Asian options introduce innovative payoff calculation methods that cater to diverse investment objectives and market conditions. Among the array of Asian option types, geometric and arithmetic Asian options emerge as prominent variations, each with its own distinctive characteristics and applications.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2'>
                    Geometric Asian options, one of the notable types within the Asian options category, derive their payoff from the geometric average price of the underlying asset over a predetermined period. This averaging method provides investors with a smoothed-out outcome, reflecting the asset's long-term performance while mitigating the impact of short-term price fluctuations. Geometric Asian options are particularly favored by investors seeking stability and consistency in their investment strategies, as they offer a reliable approach to managing risk in volatile market environments.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2'>
                    In contrast, arithmetic Asian options present a different perspective on payoff calculation, relying on the arithmetic average price of the underlying asset over a predefined period. Unlike geometric options, arithmetic Asian options may offer a more nuanced reflection of short-term price movements, capturing fluctuations with greater precision. This variation in payoff calculation makes arithmetic Asian options attractive to traders looking to capitalize on short-term market trends or hedge against immediate volatility in asset prices.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    These nuanced differences in payoff calculation methods empower investors with flexibility and adaptability in navigating the complexities of financial markets. By incorporating both geometric and arithmetic Asian options into their investment portfolios, traders can diversify their strategies and optimize risk management efforts. With their ability to harness the average price movement of underlying assets over time, Asian options contribute to the richness and diversity of investment opportunities available in the ever-evolving global financial landscape.
                </p>
            </div>

            <div className='max-w-[800ox] mt-[0px] h-screen w-full mx-auto text-center flex flex-col justify-center z-0 overflow-hidden'>
                <p className='text-white text-3xl font-bold mt-[10px] mb-10'>
                    How To Price Options
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base'>
                    Pricing options involves intricate calculations to determine their value within the financial markets. Specifically, Asian options present a unique challenge due to their distinctive payoff calculation methods. Employing Monte Carlo simulations, analysts can simulate numerous future scenarios to estimate option prices accurately. By incorporating geometric and arithmetic averaging methods, Asian option pricing models capture the essence of the underlying asset's price dynamics over a predefined period, offering investors insights into potential outcomes. Geometric Asian options smooth out fluctuations by computing the geometric average price, while arithmetic Asian options provide a more nuanced reflection of short-term movements. This nuanced approach enables investors to tailor their strategies based on their risk tolerance and market expectations.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2'>
                    Furthermore, while Asian options provide valuable insights, European options pricing can also be essential in understanding market dynamics. Utilizing the Black-Scholes-Merton closed-form solution, analysts can calculate European option prices efficiently based on known variables such as the underlying asset's price, strike price, risk-free rate, time to maturity, and volatility. This method offers a streamlined approach, particularly suitable for European options with straightforward payoff structures.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2'>
                    Moreover, integrating both Asian and European option pricing methods offers a comprehensive approach to risk management and speculation. By leveraging Monte Carlo simulations for Asian options and Black-Scholes-Merton for European options, investors can capitalize on the strengths of each model while mitigating potential drawbacks. Additionally, employing control variates further enhances the accuracy of option pricing by reducing variance and improving convergence rates. This integration of diverse pricing methodologies empowers investors with robust tools to navigate the complexities of financial markets effectively.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-8'>
                    To Learn more about the specific implementation of Pricing Asian Options within this environment, have a look at my dissertation highlighting my exact calculations within MATLAB.
                </p>
                <div className='max-w-[800ox] mt-4 w-full mx-auto text-center flex flex-col justify-center z-0 overflow-hidden'>
                <a href="https://drive.google.com/file/d/16yg-IC3jKwaTGvrEP8HO1lrmBXpMAGSS/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline font-bold">
                    Click here to access my dissertation
                </a>
                </div>

            </div>
        
        </div>
        
    )
}
export default LearnMore;