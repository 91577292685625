import React from 'react';

function VarianceReduction(){

    return(
        <div>
            <div className='max-w-[800ox] mt-[0px] w-full h-screen mx-auto text-center flex flex-col justify-center z-0 overflow-hidden'>
                <p className='text-white text-3xl font-bold mt-[10px] mb-10'>
                    Different Methods Of Variance Reduction
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base'>
                    When pricing Asian options through Monte Carlo simulations, variance reduction techniques play a crucial role in improving computational efficiency and accuracy. These methods help mitigate the inherent randomness in simulations, leading to more reliable option valuations.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2'>
                    One popular technique is Control Variates, where we identify another financial instrument whose price correlates with the Asian option's payoff. By including this correlated instrument in the simulation, we can reduce the variance of the estimator, resulting in more accurate pricing estimates.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2'>
                    Another effective method is Antithetic Variates, which involves generating pairs of random variates that are negatively correlated. By simulating both the original asset price path and its "antithetic" counterpart, we can average their payoffs to reduce variance and obtain more precise option valuations.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    Stratified Sampling is also commonly used, where the simulation space is divided into different strata based on factors like market volatility or trend. By ensuring each stratum is adequately represented in the simulation, we can achieve more accurate estimates with reduced variance.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    Control Variate with Brownian Bridge combines the control variate technique with a Brownian bridge adjustment. The Brownian bridge ensures simulated asset price paths pass through the initial and final prices accurately, enhancing the precision of option pricing alongside variance reduction.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    Finally, Moment Matching involves adjusting simulated asset price distributions to match specific moments of the true distribution, such as mean and variance. This technique ensures simulated paths accurately represent the underlying asset's behavior, leading to more reliable option valuations with reduced variance.
                </p>
                <p className='text-white max-w-[1000px] mx-auto font-bold text-base mt-2 '>
                    By incorporating these variance reduction techniques into Monte Carlo simulations for pricing Asian options, financial practitioners can enhance the efficiency and accuracy of their valuation models, enabling more informed decision-making in derivative trading and risk management.
                </p>   
            </div>
        </div>
    )
}
export default VarianceReduction