import React from 'react';
import { useNavigate } from 'react-router-dom';
import  {ReactTyped}  from 'react-typed';


function LandingPage(){
    const navigate = useNavigate()

    const clickedButton = () =>{
        //alert('Asian option pricing is in progress, stay tuned!')
        navigate("/asian-option-calculator")
    }
    return (
        
        <div className='mt-16 z-0'>
            
            <div className='max-w-[800ox] mt-[10px] w-full h-screen mx-auto text-center flex flex-col justify-center z-0'>
                <div>
                    <ReactTyped
                    className='text-gray-300 md:text-5xl sm:text-2xl ' 
                    strings={['WELCOME TO THE ASIAN OPTION PRICING ENGINE!']} 
                    typeSpeed={40} 
                    backSpeed={30}
                    loop
                    />
                </div>
                <p className='text-gray-300 md:text-3xl'> Simulate Asian Option prices using Monte Carlo simulation.</p>
                <p className='text-gray-300 md:text-3xl'> Additionally, price European Options using a closed form solultion.</p>
                <button onClick={clickedButton} className="bg-[#64748b] hover:bg-[#94a3b8] w-[200px] text-white font-bold my-6 py-3 px-4 rounded-md mx-auto  justify-start" >Get Started</button>           
            </div>
            
            
              
            
        </div>
      
            
        
    )
}

export default LandingPage;