import React, {useState} from "react";
import axios from "axios";
import '@fortawesome/fontawesome-free/css/all.css';
function AsianOptionPricingCalc(){
    const [data, setData] = useState(null);
    const [aparams,setAparams] = useState(
        {S0 : 100,
        K : 100,
        r : 0.01,
        T : 1,
        sigma : 0.2,
        N : 252,
        M : 100,
        option_type : 'arithmetic',
        use_control_variate : false}
     
    )
    
    
   
    
    const sendPostRequest = async () => {
        console.log(aparams);
        try {
            await axios.post('https://asianoptionpricingengine.com/api/stock_price', aparams, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
        } catch (error) {
            console.error('Error: ', error);
        }
    };

    const sendGetRequest = async () => {
        try {
            const result = await axios.get('https://asianoptionpricingengine.com/api/',{
                withCredentials:true},);
            if (result.data) {
                setData(result.data);
            } else {
                console.error('No data received from server');
            }
        } catch (error) {
            console.error('Error: ', error);
        }
    };

   const buttonClick = async () =>{
    await sendPostRequest();
    await sendGetRequest();
};
const [showVariance,setShowVariance] = useState(false)


    
return (
    
    <div className='max-w-[800ox] mt-[5px] w-full h-screen mx-auto text-center flex flex-col justify-center bg py-1 overflow-hidden '>
        <div>
        <div style={{ marginBottom: '1rem' }} className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 font-bold">S0</span>
            <div className="relative">
                <input
                    className="bg-[#64748b] h-10 w-56 rounded-lg pr-8 text-white"
                    style={{ padding: '0.5rem' }}
                    type="number"
                    value={aparams.S0}
                    onChange={(e) => setAparams({ ...aparams, S0: parseFloat(e.target.value) })}
                />
                <span className="absolute right-0 top-0 flex items-center h-full pr-2 -mr-8">
                    <span className="text-white cursor-pointer" title="The current price of the underlying asset.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            </div>
        </div>
        <div style={{ marginBottom: '1rem' }} className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 font-bold">K</span>
            <div className="relative">
                <input
                    className="bg-[#64748b] h-10 w-56 rounded-lg pr-8 text-white"
                    style={{ padding: '0.5rem' }}
                    type="number"
                    value={aparams.K}
                    onChange={(e) => setAparams({ ...aparams, K: parseFloat(e.target.value) })}
                />
                <span className="absolute right-0 top-0 flex items-center h-full pr-2 -mr-8">
                    <span className="text-white cursor-pointer" title="The strike price of the option.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            </div>
        </div>
        <div style={{ marginBottom: '1rem' }} className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 font-bold">r</span>
            <div className="relative">
                <input
                    className="bg-[#64748b] h-10 w-56 rounded-lg pr-8 text-white"
                    style={{ padding: '0.5rem' }}
                    type="number"
                    value={aparams.r}
                    onChange={(e) => setAparams({ ...aparams, r: parseFloat(e.target.value) })}
                />
                <span className="absolute right-0 top-0 flex items-center h-full pr-2 -mr-8">
                    <span className="text-white cursor-pointer" title="The risk-free interest rate.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            </div>
        </div>
        <div style={{ marginBottom: '1rem' }} className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 font-bold">T</span>
            <div className="relative">
                <input
                    className="bg-[#64748b] h-10 w-56 rounded-lg pr-8 text-white"
                    style={{ padding: '0.5rem' }}
                    type="number"
                    value={aparams.T}
                    onChange={(e) => setAparams({ ...aparams, T: parseFloat(e.target.value) })}
                />
                <span className="absolute right-0 top-0 flex items-center h-full pr-2 -mr-8">
                    <span className="text-white cursor-pointer" title="The time to expiration for the option, in years.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            </div>
        </div>
        <div style={{ marginBottom: '1rem' }} className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 font-bold">Sigma</span>
            <div className="relative">
                <input
                    className="bg-[#64748b] h-10 w-56 rounded-lg pr-8 text-white"
                    style={{ padding: '0.5rem' }}
                    type="number"
                    value={aparams.sigma}
                    onChange={(e) => setAparams({ ...aparams, sigma: parseFloat(e.target.value) })}
                />
                <span className="absolute right-0 top-0 flex items-center h-full pr-2 -mr-8">
                    <span className="text-white cursor-pointer" title="The volatility of the underlying asset.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            </div>
        </div>
        <div style={{ marginBottom: '1rem' }} className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 font-bold">N</span>
            <div className="relative">
                <input
                    className="bg-[#64748b] h-10 w-56 rounded-lg pr-8 text-white"
                    style={{ padding: '0.5rem' }}
                    type="number"
                    value={aparams.N}
                    onChange={(e) => setAparams({ ...aparams, N: parseFloat(e.target.value) })}
                />
                <span className="absolute right-0 top-0 flex items-center h-full pr-2 -mr-8">
                    <span className="text-white cursor-pointer" title="The number of monitoring dates (for averaging), often representing the number of trading days in a year.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            </div>
        </div>
        <div style={{ marginBottom: '1rem' }} className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 font-bold">M</span>
            <div className="relative">
                <input
                    className="bg-[#64748b] h-10 w-56 rounded-lg pr-8 text-white"
                    style={{ padding: '0.5rem' }}
                    type="number"
                    value={aparams.M}
                    onChange={(e) => setAparams({ ...aparams, M: parseFloat(e.target.value) })}
                />
                <span className="absolute right-0 top-0 flex items-center h-full pr-2 -mr-8">
                    <span className="text-white cursor-pointer" title="The number of simulation paths.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            </div>           
        </div>   
        <div style={{ marginBottom: '1rem' }} className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 font-bold">Option Type</span>
            <div className="relative">
                <input
                    className="bg-[#64748b] h-10 w-56 rounded-lg pr-8 text-white"
                    style={{ padding: '0.5rem' }}
                    type="string"
                    value={aparams.option_type}
                    onChange={(e) => setAparams({ ...aparams, option_type:e.target.value })}
                />
                <span className="absolute right-0 top-0 flex items-center h-full pr-2 -mr-8">
                    <span className="text-white cursor-pointer" title="The type of Asian option, choose between arithmetic and geometric.">
                        <i className="fas fa-info-circle"></i>
                    </span>
                </span>
            </div>           
        </div>   

         
        <div className="flex items-center justify-center">
            <span className="pr-2 text-white w-16 whitespace-nowrap mr-6 font-bold">Use control variate?</span>
            <div className="relative flex items-center "> 
                <input 
                    type="checkbox"
                    className="absolute left-[70px]"
                    checked={aparams.use_control_variate}
                    onChange={(e) => setAparams({ ...aparams, use_control_variate: e.target.checked })}
                />
                <span className="text-white cursor-pointer ml-2 absolute left-[100px]" title="Calculate Asian options utilising European options as the control variate."> {/* Changed ml-2 */}
                    <i className="fas fa-info-circle"></i>
                </span>
            </div>
        </div>
        <div className="flex items-center justify-center">
            <button className="text-white ml-16 " onClick={() => {buttonClick(); setShowVariance(aparams.use_control_variate);}}>Calculate option price!</button>
        </div>
    </div>

            
            
            {data && (
                <div className="text-white my-6 px-auto mx-auto rounded-lg text-center" style={{ display: 'inline-block', paddingLeft: '60px',}}>
                <div>Asian Call price! {data.Asian_Call_Price}</div>
                <div>Asian Put price! {data.Asian_Put_Price}</div>
                <div>European Call price! {data.European_Call_Price}</div>
                <div>European Put price! {data.European_Put_Price}</div>
            {showVariance && (
                <>
                <div>variance call without cv {data.vcall_without_cv}</div>
                <div>variance put without cv {data.vput_without_cv}</div>
                <div>variance call with cv {data.vcall_with_cv}</div>
                <div>variance put with cv {data.vput_with_cv}</div>
                </>
        )}
    </div>
)}
            
            
            
               
        </div>
    );
}

export default AsianOptionPricingCalc;